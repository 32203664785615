<template>
    <div class="header-menu-item">
        <a :href="item.url">
            <img :src="item.image" :alt="`${item.title} image`" />
            <span>
                <h3>{{ item.title }}</h3>
                <span>
                    {{ item.description }}
                </span>
            </span>
        </a>
        <div class="sub-menu-items" v-if="item.children">
            <ul>
                <li v-for="(link, i) in item.children" :key="i">
                    <a :href="link.url">
                        <!-- <font-awesome-icon viewBox="0 0 600 600" icon="custom-chevron-right"/>  -->
                        {{ link.title }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { customChevronRight } from "../../../assets/icons";

// library.add( customChevronRight );

export default {
    name: 'HeaderMenuBlockItem',
    components: {},
    data() {
        return {
            buildItem: {}
        }
    },
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.header-menu-item {
    padding: 15px;
    transition: 0.5s background, 0.5s border-radius;
    margin-bottom: 30px;

    &:hover {
        background: #f5f3f3;
        border-radius: 15px;
    }

    h3 {
        font-size: 16px;
    }

    a {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        color: #0d0e10;

        img {
            margin-top: 2px;
            max-width: 32px;
            max-height: 18px;
            margin-right: 12px;
            height: auto;
            width: 100%;
            display: block;
        }

        &:focus-visible {
            outline: none;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .sub-menu-items {
        margin-left: 44px;

        ul {
            list-style: none;
            padding: 0;

            a {
                padding-top: 10px;

                &:hover {
                    color: #56585c;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .header-menu-item {
        a {
            color: white;
        }
    }
}
</style>
